exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-english-js": () => import("./../../../src/pages/english.js" /* webpackChunkName: "component---src-pages-english-js" */),
  "component---src-pages-hinnoittelu-js": () => import("./../../../src/pages/hinnoittelu.js" /* webpackChunkName: "component---src-pages-hinnoittelu-js" */),
  "component---src-pages-ideat-js": () => import("./../../../src/pages/ideat.js" /* webpackChunkName: "component---src-pages-ideat-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-svenska-js": () => import("./../../../src/pages/svenska.js" /* webpackChunkName: "component---src-pages-svenska-js" */),
  "component---src-pages-tech-js": () => import("./../../../src/pages/tech.js" /* webpackChunkName: "component---src-pages-tech-js" */),
  "component---src-templates-article-js-content-file-path-app-src-articles-2022-09-start-with-plus-strategiakartta-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/app/src/articles/2022-09-start-with-plus-strategiakartta.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-app-src-articles-2022-09-start-with-plus-strategiakartta-mdx" */),
  "component---src-templates-article-js-content-file-path-app-src-articles-2022-09-start-with-why-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/app/src/articles/2022-09-start-with-why.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-app-src-articles-2022-09-start-with-why-mdx" */),
  "component---src-templates-article-js-content-file-path-app-src-articles-2022-09-strategiakartta-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/app/src/articles/2022-09-strategiakartta.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-app-src-articles-2022-09-strategiakartta-mdx" */),
  "component---src-templates-article-js-content-file-path-app-src-articles-2022-10-asiantuntijan-intutitio-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/app/src/articles/2022-10-asiantuntijan-intutitio.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-app-src-articles-2022-10-asiantuntijan-intutitio-mdx" */),
  "component---src-templates-article-js-content-file-path-app-src-articles-2022-10-uskottava-vs-todennakoinen-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/app/src/articles/2022-10-uskottava-vs-todennakoinen.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-app-src-articles-2022-10-uskottava-vs-todennakoinen-mdx" */),
  "component---src-templates-article-js-content-file-path-app-src-articles-2022-11-kuka-vastaa-strategian-jalkauttamisesta-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/app/src/articles/2022-11-kuka-vastaa-strategian-jalkauttamisesta.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-app-src-articles-2022-11-kuka-vastaa-strategian-jalkauttamisesta-mdx" */),
  "component---src-templates-article-js-content-file-path-app-src-articles-2023-02-kolme-rakennetta-yrityksen-prosessien-mallintamiseen-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/app/src/articles/2023-02-Kolme rakennetta yrityksen prosessien mallintamiseen.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-app-src-articles-2023-02-kolme-rakennetta-yrityksen-prosessien-mallintamiseen-mdx" */),
  "component---src-templates-article-js-content-file-path-app-src-articles-2023-06-deft-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/app/src/articles/2023-06-DEFT.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-app-src-articles-2023-06-deft-mdx" */),
  "component---src-templates-article-js-content-file-path-app-src-articles-2023-06-fisherin-muutoksen-kayra-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/app/src/articles/2023-06-Fisherin-muutoksen-kayra.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-app-src-articles-2023-06-fisherin-muutoksen-kayra-mdx" */)
}

